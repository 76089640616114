import { PayloadAction } from "@reduxjs/toolkit";
import { put, takeLatest } from "redux-saga/effects";

import { trackingActions } from "./actions";
import { TrackingConfig } from "./types";
import { siteActions } from "../site/actions";
import { SiteConfig } from "../site/types";

function* onSiteSetCodeWithConfig(action: PayloadAction<{ config: SiteConfig }>): Generator {
  const tracking = action.payload.config?.tracking;
  if (tracking) {
    yield put(trackingActions.setConfig(tracking as TrackingConfig));
  }
}

export default function* sagas(): Generator {
  yield takeLatest(siteActions.setCodeWithConfig, onSiteSetCodeWithConfig);
}
