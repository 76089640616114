import { FORM_ERROR, SubmissionErrors } from "final-form";

const DEFAULT_ERROR_MESSAGE = "Ceva nu a funcționat. Te rugăm să încerci din nou mai târziu.";

type QueryError = {
  status?: number;
  data?: any;
} & Error;

const checkIfIsQueryError = (error: any): error is QueryError => {
  return error && error.status !== undefined;
};

export const getErrorDetail = (error: unknown): string | undefined => {
  if (checkIfIsQueryError(error)) {
    return error.data?.detail;
  }
};

export const getErrorCode = (error: unknown): string | undefined => {
  if (checkIfIsQueryError(error)) {
    return error.data?.error_code;
  }
};

export const getFieldErrors = (error: unknown, field: string): string[] | undefined => {
  if (checkIfIsQueryError(error)) {
    return error.data?.error?.[field];
  }
};

export const getFieldError = (error: unknown, field: string): string | undefined => {
  const errors = getFieldErrors(error, field);
  if (Array.isArray(errors) && errors.length > 0) {
    return errors.join(". ");
  }
};

export const getErrorStatus = (error: unknown): number | undefined => {
  if (checkIfIsQueryError(error)) {
    return error.status;
  }
};
export const convertErrors = (error: unknown, defMessage: string = DEFAULT_ERROR_MESSAGE): SubmissionErrors => {
  let submitErrors: SubmissionErrors = {};

  if (checkIfIsQueryError(error)) {
    return convertMutationError(error, defMessage);
  }

  // if no errors, set default error
  if (Object.keys(submitErrors).length == 0) {
    submitErrors[FORM_ERROR] = defMessage;
  }
  return submitErrors;
};

const convertMutationError = (error: QueryError, defMessage: string = DEFAULT_ERROR_MESSAGE): SubmissionErrors => {
  const errors: SubmissionErrors = {};

  // we care of 400 errors only
  if (!error.status || Math.floor(error.status / 100) !== 4) {
    console.error("Unable to convert Error", error);
    errors[FORM_ERROR] = defMessage;
    return errors;
  }

  // if no data, return empty errors
  if (!error.data?.error || Array.isArray(error.data?.error) || typeof error.data?.error !== "object") {
    console.error("Unable to convert Error", error);
    errors[FORM_ERROR] = defMessage;
    return errors;
  }

  const data: Record<string, any> = error.data?.error;
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const value = Array.isArray(data[key]) ? data[key].join(". ") : data[key];
      if (key == "detail") {
        errors[FORM_ERROR] = value;
      } else if (key == "non_field_errors") {
        errors[FORM_ERROR] = value;
      } else {
        errors[key] = value;
      }
    }
  }
  return errors;
};

export const addQueryDictToUrl = (url: string, query?: Record<string, any> | void | undefined): string => {
  if (!query || Object.keys(query).length === 0) return url;

  let urlObj: URL;
  const dummyBase = "https://example.com";
  if (url.startsWith("http://") || url.startsWith("https://")) {
    urlObj = new URL(url);
  } else {
    // Create a base URL to handle path-only URLs
    urlObj = new URL(url, dummyBase);
  }

  Object.keys(query).forEach((key) => {
    urlObj.searchParams.append(key, query[key].toString());
  });
  if (urlObj.href.startsWith(dummyBase)) {
    return urlObj.href.replace(urlObj.origin, "");
  }
  return urlObj.href;
};
